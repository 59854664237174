import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import MarkdownWrapper from '../../../../components/MarkdownWrapper';
import Layout from '../../../../components/Layout';
import Accordion from '../../../../components/Accordion';
export const _frontmatter = {
  "title": "AWS AppConfig",
  "path": "/knowledge/tech/aws",
  "date": "2023-01-29T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout title={props.pageContext.frontmatter.title} location={props.path} mdxType="Layout">
      <MarkdownWrapper mdxType="MarkdownWrapper">
        <h1 {...{
          "id": "aws--appconfig",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h1" {...{
            "href": "#aws--appconfig",
            "aria-label": "aws  appconfig permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`AWS — AppConfig`}</h1>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/6e153cfbcd624bb65b22dcaa5ed547b4/f663d/awsconfig-diagram.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "77.33333333333333%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAABc0lEQVQ4y42T607DMAyF8/4vxIPwDwmJSmv3Y13X69b1EqPPcMYIHVDJqhPbx8cnSVjX1ZZlMf7yYzTrqjd7fX6ytnyxNdq3nNSIzfPsfkgBZdHM5mU1vkgHM9tqPk2TXS4XO5/P7jsg6MMwuBEk0DS1lWVpVVVZ13Uew2ePYtbjOFrTNFYUhVtd118MSer73sFoAPDQ9w52PB49mdj1evUYfzHFx6hzwPtEaeFjx3gbl/+Wdj80BCzPcx8H+vgAk7RljzSXBZAZVSNrtFT8v4AUCxoNYETWoSjpntl/LEib/X7v7GDJ6BzEbrfzBuQ8kiBtGigA4HA4uI6n08kZ6rQltg5IBB75gfvE3YKdmHHiJNAM5uwjh+4pcekumYixH9LuGo99irIsc+YUSxIA8Nu2dRDA1DSk4t/r8cGw+Cycbq8DOWig5yb2N4bpKWrdtp1lWW5lWXkB+mpUSYTPa2INgfDbFViW1caRKxV/yLJlEHkHM+CU92w8BgoAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "diagram",
                "title": "diagram",
                "src": "/static/6e153cfbcd624bb65b22dcaa5ed547b4/c1b63/awsconfig-diagram.png",
                "srcSet": ["/static/6e153cfbcd624bb65b22dcaa5ed547b4/5a46d/awsconfig-diagram.png 300w", "/static/6e153cfbcd624bb65b22dcaa5ed547b4/0a47e/awsconfig-diagram.png 600w", "/static/6e153cfbcd624bb65b22dcaa5ed547b4/c1b63/awsconfig-diagram.png 1200w", "/static/6e153cfbcd624bb65b22dcaa5ed547b4/f663d/awsconfig-diagram.png 1511w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "creating-and-using-a-flag-in-a-web-app",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#creating-and-using-a-flag-in-a-web-app",
            "aria-label": "creating and using a flag in a web app permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Creating and using a flag in a web app`}</h2>
        <p>{`We have a web app that displays cat photos. We want to also show dog photos, but we want to do it by enabling the feature linearly for 10 users, 20, 30.... etc in a gradual way.`}</p>
        <h3 {...{
          "id": "creating-and-deploying-a-configuration",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#creating-and-deploying-a-configuration",
            "aria-label": "creating and deploying a configuration permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Creating and deploying a configuration`}</h3>
        <p>{`Here we'll create the feature flag with the name `}<inlineCode parentName="p">{`dogs_panel`}</inlineCode>{` that's off by default.`}</p>
        <h4 {...{
          "id": "create-the-app",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#create-the-app",
            "aria-label": "create the app permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Create the app`}</h4>
        <Accordion summaryText="Steps to create the app and configs" mdxType="Accordion">
          <p>{`In this step, we created an app named Cute pets site`}</p>
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1041px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/4945ce940e230b731ed13052af0dc7f1/ee9b6/awsconfig-create-app.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "59.333333333333336%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAABS0lEQVQoz5WTTU7DMBSEff8rIHEKLgBrxIYNC9omxURN08SO//M76DlpS1FRU0ufxrKdyYvfhG13BVY8R5rlSLIcWVEi3++R7XYQUkJpjVqpxbAvzvG53kys1iirCtqYaERKmKg6rt2CFYcDkiTFJkkghIBzHsZYWHtGWQ9tPZqmuUqY1TkHRmVqrWGtmwyci3PanPBQroENLfq+R9d1EZr/ZhgGhBAmw+JQom07jEDcGMbxgnEc4np/ZDb4O9q2BatrBf6dxUbQwZFMhiHqkbPx/9BoyNB7D+c9vA+nNy+Bzl6tkO6JLpW+n8xJl+BJ54ZcNIWyxjlHmm5BETLWxorPTVkAPeNsjBeTUqKqxKlCdxcOLjRwHeB7wIQOTNY1KiFiZMyMvYE5qYOpcpj1a0TxD7Bjwu/5vQilFPZVjXLzDvn8APnyCPH2hB9iXp9aXgUEzwAAAABJRU5ErkJggg==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "create the app",
                  "title": "create the app",
                  "src": "/static/4945ce940e230b731ed13052af0dc7f1/ee9b6/awsconfig-create-app.png",
                  "srcSet": ["/static/4945ce940e230b731ed13052af0dc7f1/5a46d/awsconfig-create-app.png 300w", "/static/4945ce940e230b731ed13052af0dc7f1/0a47e/awsconfig-create-app.png 600w", "/static/4945ce940e230b731ed13052af0dc7f1/ee9b6/awsconfig-create-app.png 1041w"],
                  "sizes": "(max-width: 1041px) 100vw, 1041px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
          <h4 {...{
            "id": "create-the-configuration-profile",
            "style": {
              "position": "relative"
            }
          }}><a parentName="h4" {...{
              "href": "#create-the-configuration-profile",
              "aria-label": "create the configuration profile permalink",
              "className": "anchor before"
            }}><svg parentName="a" {...{
                "aria-hidden": "true",
                "focusable": "false",
                "height": "16",
                "version": "1.1",
                "viewBox": "0 0 16 16",
                "width": "16"
              }}><path parentName="svg" {...{
                  "fillRule": "evenodd",
                  "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
                }}></path></svg></a>{`Create the configuration profile`}</h4>
          <p>{`In this step, we created a configuration profile named AllowedPanels for Cute pets site`}</p>
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1040px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/5d8cb5133ab143de152d1575b9729987/1ff84/awsconfig-create-config-profile.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "58.666666666666664%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAABaklEQVQoz42T2W6DMBBF+fO8tf2c9iF96kdUfWsWqNgJAhuwjW2WW3mSVNkaBeloQJq5uuNrvE1cwE92CLMdgrSk6qclkizHTxghjBMkaUZUNUPbdWja9l+8zXaLre/DDwLCva/Wa2z9AN+rNaI4hlKKhB7By7IcUZwQYRQhTlyNIYQkIaF6cKEg+x5Ga+g7dELAq+sanDeoGSMY41SllJBSQSqNrrcwdsA4jhiGkeotBAkyRk4un2meMU3TH+N0PjzP89WMkBJeUeyQZjk5co12GIjj0HRgfgASdGu53d2HVIrWFFJBa3MmeCrsHN9y6Ex5tAKtNJEzYy2stTDGQhtzGxeCMfu+EyiUvCgo3TCM4ALqtaYzVX1/H9dzAV2bqnIpc7qULqVbjVc4QW2uaJ3DXVmS0NGZvCNEZ6x6yK6BrAtIdqDe0zYcntv73q90RtOgqFtUXx/gbwuw5TPY8gns/QXsdQEefOIXIE2aRNSZdjEAAAAASUVORK5CYII=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "create the configuration",
                  "title": "create the configuration",
                  "src": "/static/5d8cb5133ab143de152d1575b9729987/1ff84/awsconfig-create-config-profile.png",
                  "srcSet": ["/static/5d8cb5133ab143de152d1575b9729987/5a46d/awsconfig-create-config-profile.png 300w", "/static/5d8cb5133ab143de152d1575b9729987/0a47e/awsconfig-create-config-profile.png 600w", "/static/5d8cb5133ab143de152d1575b9729987/1ff84/awsconfig-create-config-profile.png 1040w"],
                  "sizes": "(max-width: 1040px) 100vw, 1040px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
          <h4 {...{
            "id": "create-the-feature-flag",
            "style": {
              "position": "relative"
            }
          }}><a parentName="h4" {...{
              "href": "#create-the-feature-flag",
              "aria-label": "create the feature flag permalink",
              "className": "anchor before"
            }}><svg parentName="a" {...{
                "aria-hidden": "true",
                "focusable": "false",
                "height": "16",
                "version": "1.1",
                "viewBox": "0 0 16 16",
                "width": "16"
              }}><path parentName="svg" {...{
                  "fillRule": "evenodd",
                  "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
                }}></path></svg></a>{`Create the feature flag`}</h4>
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1092px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/8423ab8164cd4e6a52fc9cdba2f07254/2cffa/awsconfig-create-configs.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "30.66666666666667%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA6klEQVQY032R3UrEMBBG+/5P5I3vIIgXooIW3C1N0uZnmmw3SY80CyIs9eIwc3WY75vOOseoNKNSKG0YlSGIEGNkWZaGiOBDOMDjw4IPse1dCAE3z0zGNIw6NVnOmVIK27aRr7lJnff37DLb46d3nF/orET6ceKsLcrOaP3E7ANKKYyZSCmx1dIuPRS6Aa8/cLOls2nlaxI+R0tvAoNcibk22XkYOI0GyRBTwjnXYt5LpYn3vUvrylo3LqX+kmullsIlb8TTK+n5gUX2voRw2OWNbo+yx/pLbDMiacV/v+FfHgl7+f8+5yb8ASOiy8ZOQAX6AAAAAElFTkSuQmCC')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "select the feature flag",
                  "title": "select the feature flag",
                  "src": "/static/8423ab8164cd4e6a52fc9cdba2f07254/2cffa/awsconfig-create-configs.png",
                  "srcSet": ["/static/8423ab8164cd4e6a52fc9cdba2f07254/5a46d/awsconfig-create-configs.png 300w", "/static/8423ab8164cd4e6a52fc9cdba2f07254/0a47e/awsconfig-create-configs.png 600w", "/static/8423ab8164cd4e6a52fc9cdba2f07254/2cffa/awsconfig-create-configs.png 1092w"],
                  "sizes": "(max-width: 1092px) 100vw, 1092px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1200px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/6e0faebbc319eb347538f0fccb59c165/b79a5/awsconfig-create-flag.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "58.00000000000001%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAABNUlEQVQoz52TWU7DMBCGff9jcAvEMxJnQOIBtdAmjdc4XhLbPxo3VO4mFSx9cuIZ/bN4zD423+iERscl9lxhzyV2XKE/DJBKQRvzAArjaGBGC7bdbqC1hvcOzk1wzp2Ypqli1/0aBztZ8K9n7D5fwIUEGziHEBLO+TOxFu995Z59sgpS9BBSgh2Goaa9pIRlWa5IKVWxGGP9vvZJWFJGKYDzHoz6FOe5Cqacz0kJuZRTdvnS3vrlfBRUWsMYU0VLKdVwCZ3fs7U+FJQZM8KHeIr0CG2QGojqxVoyXYhSuvZonucKZRvjcZ8b4goJtqusgj4EMGttHQESJMK6t//h4jyEcBMaL9Z1/XFsvL95y49CrZioh0KqOuF00PblL/yuWjKNjdL0bMZ/occRwzBAvT5Bv7/hB22rpnls1xqxAAAAAElFTkSuQmCC')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "create the feature flag",
                  "title": "create the feature flag",
                  "src": "/static/6e0faebbc319eb347538f0fccb59c165/c1b63/awsconfig-create-flag.png",
                  "srcSet": ["/static/6e0faebbc319eb347538f0fccb59c165/5a46d/awsconfig-create-flag.png 300w", "/static/6e0faebbc319eb347538f0fccb59c165/0a47e/awsconfig-create-flag.png 600w", "/static/6e0faebbc319eb347538f0fccb59c165/c1b63/awsconfig-create-flag.png 1200w", "/static/6e0faebbc319eb347538f0fccb59c165/b79a5/awsconfig-create-flag.png 1372w"],
                  "sizes": "(max-width: 1200px) 100vw, 1200px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
          <h4 {...{
            "id": "create-the-environment",
            "style": {
              "position": "relative"
            }
          }}><a parentName="h4" {...{
              "href": "#create-the-environment",
              "aria-label": "create the environment permalink",
              "className": "anchor before"
            }}><svg parentName="a" {...{
                "aria-hidden": "true",
                "focusable": "false",
                "height": "16",
                "version": "1.1",
                "viewBox": "0 0 16 16",
                "width": "16"
              }}><path parentName="svg" {...{
                  "fillRule": "evenodd",
                  "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
                }}></path></svg></a>{`Create the environment`}</h4>
          <p>{`In this step, we created a Production environment that'll be deploy the configuration profile to.`}</p>
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1039px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/d1b2ccd188741f1aff16fe97fdd4e054/22475/awsconfig-create-env.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "66%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPUlEQVQ4y52Ty27CMBBF/fd8TZes+gdddovUgiFx4rzsxM8QuNU4gBCqSoqlo4xG1vWdR9hOSGy4wNdhhgsJUSsUTYdCSuRFibbr0A8DdN8/hR2yDDvOwTnH93YLIQSssxiMgbEG1s4xCS6B0ev7/QGc7yFlBe89hsHAGANlHLTxiDEuwlgLpnWfBMgJJXwICCEixAgXRvh4xDRNTzmdTnDeg5FNckkJOvR9ZHrkIvB4yAxTWiPLcpSlTC7p4vl8/jc3QbJJpJKNxfHy+l9cXf7q0DoH76lvIfWNkksJ91yH0imVpkyrMY4jnHMvQxpMa42iLJELAVEUqfTUhlcFaSiyqtA0LZq2nQUXCthbPM8hCVLJdd2kxaT9uw5pCdS7uRqbSIK0hxQs+U/vUVqhbDW6jzeo9QrdegX1+Y4f6mvswQQoM3oAAAAASUVORK5CYII=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "create the environment",
                  "title": "create the environment",
                  "src": "/static/d1b2ccd188741f1aff16fe97fdd4e054/22475/awsconfig-create-env.png",
                  "srcSet": ["/static/d1b2ccd188741f1aff16fe97fdd4e054/5a46d/awsconfig-create-env.png 300w", "/static/d1b2ccd188741f1aff16fe97fdd4e054/0a47e/awsconfig-create-env.png 600w", "/static/d1b2ccd188741f1aff16fe97fdd4e054/22475/awsconfig-create-env.png 1039w"],
                  "sizes": "(max-width: 1039px) 100vw, 1039px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
          <h4 {...{
            "id": "deploy",
            "style": {
              "position": "relative"
            }
          }}><a parentName="h4" {...{
              "href": "#deploy",
              "aria-label": "deploy permalink",
              "className": "anchor before"
            }}><svg parentName="a" {...{
                "aria-hidden": "true",
                "focusable": "false",
                "height": "16",
                "version": "1.1",
                "viewBox": "0 0 16 16",
                "width": "16"
              }}><path parentName="svg" {...{
                  "fillRule": "evenodd",
                  "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
                }}></path></svg></a>{`Deploy`}</h4>
          <p>{`Select the Quick deployment strategy and proceed to deploy.`}</p>
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1039px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/572e6f36d0a522ea05fcbf3f234d3e71/22475/awsconfig-select-deployment.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "66.66666666666666%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB3UlEQVQ4y4VTy47UMBDM3yNx5zM4Ii4c+QKQuDDkOZOZZBw/Y8eOnRSyvbuzA8MSqdQtp7u63eUu6u6En1WLQ93gUDUYJgrCBSYmMFIOwjgmSnElE7gQEFK+iaLve9R1jbIs0R2PUEpBa41Z62QzDIwxL+f/QswtKGXouiOOpx7Rj0lC3CrK2YDOC5xzWNf1L3jvXxBzCyklponCOpcQQsC2bXcIT9j+sDF29R7ORXIPsywopFK4EpKutVj7kPARYlzsWs0zKOOIPJlQKkyUJVLGeTr0b5CGcMNdxyFAG4MiDpJzkSrEOTya0+pXOL/DJ5JcbH8usu8Z2wZjFhRUSJwGgn6cMFIBoRfwOSP6IlrjQCQHNwbW7zDOY15cUpVMNGkQm0pXPhOGQ9ujOl1Q9wOuXIFIDSJ08kcuMXILNn0D5SdcxYKRK1Cpsyiv1E6EcW6/qhpl3aCsalRNi7rtUDcdCKVpvvH5WOex2BXW2SReJHn97fueCWNC07QYhhHn8yXjckHfn8EYz492nhECEOwRpPwAef6I1WcRIomxDmaxULPOHUaF0w+zpI248582RCc7Q0oKpfgtTisYMcHEc8lRPG9E3NP/IsWqDCEwUAHy4yvYp3egn9+Dff+C34o45uwxs81tAAAAAElFTkSuQmCC')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "deploy the configuration",
                  "title": "deploy the configuration",
                  "src": "/static/572e6f36d0a522ea05fcbf3f234d3e71/22475/awsconfig-select-deployment.png",
                  "srcSet": ["/static/572e6f36d0a522ea05fcbf3f234d3e71/5a46d/awsconfig-select-deployment.png 300w", "/static/572e6f36d0a522ea05fcbf3f234d3e71/0a47e/awsconfig-select-deployment.png 600w", "/static/572e6f36d0a522ea05fcbf3f234d3e71/22475/awsconfig-select-deployment.png 1039w"],
                  "sizes": "(max-width: 1039px) 100vw, 1039px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1033px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/9fec09078c4e3d7e5d4677ebeb10d3f5/42de8/awsconfig-start-deployment.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "67.66666666666666%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAABtUlEQVQ4y42TTZKbMBCFuX1WOUgOkKrssp0DZJm4PGMHEAYkAfoHvVQ3sseJJzXjqq/aMtLj9Wur+nlucfgt8FILHJsLxKBQ9wptL/HSSYh+RNMKNEJATxOmeeb6P6rj8zN+HQ5MXddYjMG8LFgK810lsfeoTuczTqcz6qaBsRbmL0GDxTpMxsF7/y50tuq6C/p+gJSK3TnnYK2FtQ7WORgfYUPEuq4PbNuGnInMOO9RNW3LjtK6IqUVGbhteGXjw/fQ/hACnx2lwjTN8D6gGkeJUUpIpfgNKaU33dzYth02kLhV6sSVtiulJyitOVB64EPAlvODo1zqvfDeMvUE/k7nOcNWCBa9vomGYznLgg/QNiJQJDnfHMYYi7PARtjhMI48lH4YWCSVjTGmUiMitRYTPyMntCaheV4wDCNnqJTeBUmou/S7Q+tY4HEor3AEd+vrJyOzy4rsU7gkRD/EMpT0zzDeWqcymJ24Z0jTpT8k5+dcyc5+DLfna+PKLC6gEl2HmSZcbgnflg9BN8pgkR1Me4ARR8zdCdX1slOGiupH0Bpaa4hRY/jxHfLrJ8hvnyGfvuAP7fM7nNMWfbAAAAAASUVORK5CYII=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "deploy the configuration",
                  "title": "deploy the configuration",
                  "src": "/static/9fec09078c4e3d7e5d4677ebeb10d3f5/42de8/awsconfig-start-deployment.png",
                  "srcSet": ["/static/9fec09078c4e3d7e5d4677ebeb10d3f5/5a46d/awsconfig-start-deployment.png 300w", "/static/9fec09078c4e3d7e5d4677ebeb10d3f5/0a47e/awsconfig-start-deployment.png 600w", "/static/9fec09078c4e3d7e5d4677ebeb10d3f5/42de8/awsconfig-start-deployment.png 1033w"],
                  "sizes": "(max-width: 1033px) 100vw, 1033px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1200px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/466ff7eb72986e936939af4a93e57f0d/fbfd6/awsconfig-deployed.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "64.66666666666666%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAABoElEQVQ4y4WTiY6kIBCGef8H3GQn23P0IXYrIAhyqP1PqtQee9ObJfmCVor66wBx+Dri19sBJ3mFsj3azjGqs2iUgjIG1jl01r5EG4OmVZDXG/uLj5vF76PE4SRxONf4c5J4X/fjVeNqA1TI0EP5J7W2ONcNau0gpB1wbDp8yhbvVcN8yBaf9YZC0yeYOEEPI8PfcfmmnQSJtk8QRmtUVQVrLe7zjHmamGkcMY4jpmlCKQWl5JWClBJyzjt7wbjahXYeF+Vwo9J8QttHaJ/Rh4DOGw74xDzD+wDnenjvH6KbsNBaQ0rJjY0pIcaVlFhx2/9m8Y1PtiFGCOcHGBdYaaaSV0gxhMCH9naCSy4F9/v9yU4xRKsNWtMtqmtmcZfB0/9/GIYIobTmXlBG4zqIPdsQyIebv9lf+PJQKHIp46MPNL0NChCGAX3vGR8Cl5p5usv+4184S9EqhdP5jMulglJ6dVyc0uNq/EBBqPnU38TJlIc4Z8jPRxtW2C9qget7vFpcTc48iP2if0HvlBSpB3mXCR2gy55fZFl2/d2TUsY3wVvvfYWwZcEAAAAASUVORK5CYII=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "deploy the configuration",
                  "title": "deploy the configuration",
                  "src": "/static/466ff7eb72986e936939af4a93e57f0d/c1b63/awsconfig-deployed.png",
                  "srcSet": ["/static/466ff7eb72986e936939af4a93e57f0d/5a46d/awsconfig-deployed.png 300w", "/static/466ff7eb72986e936939af4a93e57f0d/0a47e/awsconfig-deployed.png 600w", "/static/466ff7eb72986e936939af4a93e57f0d/c1b63/awsconfig-deployed.png 1200w", "/static/466ff7eb72986e936939af4a93e57f0d/fbfd6/awsconfig-deployed.png 1357w"],
                  "sizes": "(max-width: 1200px) 100vw, 1200px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Accordion>
        <h3 {...{
          "id": "getting-the-configurations-in-the-client",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#getting-the-configurations-in-the-client",
            "aria-label": "getting the configurations in the client permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Getting the configurations in the client`}</h3>
        <p><a parentName="p" {...{
            "href": "https://aws-appconfig.vercel.app/"
          }}>{`Link for the client or web app`}</a>{` (`}<em parentName="p">{`the feature flag is off`}</em>{`), `}<strong parentName="p">{`Let me know when you open it, so I can switch the feature on.`}</strong></p>
        <p>{`We'll use `}<a parentName="p" {...{
            "href": "https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-appconfigdata/index.html#description"
          }}><inlineCode parentName="a">{`@aws-sdk/client-appconfigdata`}</inlineCode></a>{` to retrieve the configurations. Here's an example with Next.js.`}</p>
        <h4 {...{
          "id": "steps",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#steps",
            "aria-label": "steps permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Steps`}</h4>
        <ol>
          <li parentName="ol">{`Initiate the AppConfig client with each layer ID and IAM user access token`}</li>
          <li parentName="ol">{`Send a `}<a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/appconfig/2019-10-09/APIReference/API_appconfigdata_StartConfigurationSession.html"
            }}>{`StartConfigurationSession command`}</a>{` to get the initial configuration token`}</li>
          <li parentName="ol">{`Send a `}<a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/appconfig/2019-10-09/APIReference/API_appconfigdata_GetLatestConfiguration.html"
            }}>{`GetLatestConfiguration command`}</a>{` to get the latest configurations`}</li>
          <li parentName="ol">{`Convert the `}<inlineCode parentName="li">{`Configuration`}</inlineCode>{` from Uint8Array to JSON`}</li>
          <li parentName="ol">{`Respond to the client`}</li>
        </ol>
        <p><inlineCode parentName="p">{`api/config.ts`}</inlineCode></p>
        <Accordion summaryText="View API code" mdxType="Accordion">
          <pre><code parentName="pre" {...{
              "className": "language-ts"
            }}>{`import type { NextApiRequest, NextApiResponse } from 'next'
import {
  AppConfigDataClient,
  GetLatestConfigurationCommand,
  StartConfigurationSessionCommand,
} from '@aws-sdk/client-appconfigdata'

type Data = {
  config: {
    string: string
  }
}

let token = null

export default async function handler(
  req: NextApiRequest,
  res: NextApiResponse<Data>
) {
  try {
    // Initiate AppConfig Client
    const client = new AppConfigDataClient({
      region: 'us-east-1',
      // IAM user access token
      credentials: {
        accessKeyId: process.env.ACCESS_KEY!,
        secretAccessKey: process.env.ACCESS_SECRET!,
      },
    })

    const params = {
      ApplicationIdentifier: process.env.APPLICATION_IDENTIFIER,
      ConfigurationProfileIdentifier:
        process.env.CONFIGURATION_PROFILE_IDENTIFIER,
      EnvironmentIdentifier: process.env.ENVIRONMENT_IDENTIFIER,
    }

    // Command used to get the initial configuration token
    const command = new StartConfigurationSessionCommand(params)

    // Store the token
    token = (await client.send(command)).InitialConfigurationToken

    // Command to get the latest configuration. It takes the initial token
    // and responds with a new token
    const getConfigsCommand = new GetLatestConfigurationCommand({
      ConfigurationToken: token,
    })

    const configsRes = await client.send(getConfigsCommand)

    // Convert Uint8Array to JSON
    const configs = JSON.parse(
      Buffer.from(configsRes.Configuration || []).toString('utf-8')
    )

    res.status(200).json({ config: configs })
  } catch (e) {
    // @ts-ignore
    res.status(500).send({ error: e })
  }
}
`}</code></pre>
        </Accordion>
        <p><inlineCode parentName="p">{`pages/home.ts`}</inlineCode></p>
        <Accordion summaryText="View Frontend code" mdxType="Accordion">
          <pre><code parentName="pre" {...{
              "className": "language-tsx"
            }}>{`import Image from 'next/image'
import { Inter } from '@next/font/google'
import useSWR from 'swr'

const inter = Inter({ subsets: ['latin'] })

// @ts-ignore
const fetcher = (...args) => fetch(...args).then((res) => res.json())

type Config = {
  config: {
    dogs_panel: {
      enabled: boolean
    }
  }
}

export default function Home() {
  const { data, error, isLoading } = useSWR<Config>('/api/config', fetcher)

  if (isLoading) return <h1>Loading...</h1>
  if (error) {
    console.log(error)
    return <h1>Something went wrong, check the console</h1>
  }

  const canViewDogs = data?.config?.dogs_panel?.enabled

  let view = (
    <div>
      <Image
        src={'http://placekitten.com/400/400'}
        alt={'Cute cat'}
        height={400}
        width={400}
      ></Image>
    </div>
  )

  if (canViewDogs) {
    view = (
      <div>
        <Image
          src={'https://placedog.net/500'}
          alt={'Cute dog'}
          width={500}
          height={500}
        ></Image>
      </div>
    )
  }

  return (
    <div className={inter.className}>
      <h1>{canViewDogs ? 'You may only see DOGS' : 'You may only see CATS'}</h1>
      <small>
        If you see dogs, it means the flag is on, if you see cats it's off
      </small>
      {view}
    </div>
  )
}
`}</code></pre>
        </Accordion>
        <h2 {...{
          "id": "freeform",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#freeform",
            "aria-label": "freeform permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Freeform`}</h2>
        <p>{`Freeform option allows you to specify your key-value pairs for configuration data instead of using a pre-defined schema.`}</p>
        <p>{`You can create a configuration file to store your application's API keys, server URLs, or text.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/31f830899d1e21aae50599af42736769/80cfc/awsconfig-freeform.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "54%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA9UlEQVQoz62S2U7EMAxF8///B2h4h6plaHZncdI7iisQs0igGR6OFF0710uiPo5HzMsCIkKM8RwiUEq3uYillCRfzfOMaZrgnIf3Hm7gBk4SW2uotV4xdL7Qcs5QR6p4twm2dOjcYAalg9omXYYQd1NmcGvovYO5wViLWlnOzCz6yFE2Vbx9WizGYw0Za0jwpSO3TSo6H6TTGAk5F2zbhtY7fAiiEe2jSsfD0GiNl+cnvB4O0OuKxoxayk6tYD4fq5QijMu3dKW1ln3JUmOUru7h29AYI0ZfVX4G/8qVYQjhbrNfDf995EdR8vxESCnLwzzC+F4nDL9YSvxKH2wAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "freeform",
                "title": "freeform",
                "src": "/static/31f830899d1e21aae50599af42736769/c1b63/awsconfig-freeform.png",
                "srcSet": ["/static/31f830899d1e21aae50599af42736769/5a46d/awsconfig-freeform.png 300w", "/static/31f830899d1e21aae50599af42736769/0a47e/awsconfig-freeform.png 600w", "/static/31f830899d1e21aae50599af42736769/c1b63/awsconfig-freeform.png 1200w", "/static/31f830899d1e21aae50599af42736769/d61c2/awsconfig-freeform.png 1800w", "/static/31f830899d1e21aae50599af42736769/80cfc/awsconfig-freeform.png 1844w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "validator",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#validator",
            "aria-label": "validator permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Validator`}</h3>
        <p>{`Allows you to validate the Freeform configuration data before it's deployed to your applications. It allows you to define a schema for your Freeform configuration data, and then use that schema to validate the configuration data before it's deployed.`}</p>
        <p>{`Validator example:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "$schema": "http://json-schema.org/draft-07/schema#",
  "type": "object",
  "properties": {
    "pikachuName": {
      "type": "string"
    },
    "age": {
      "type": "number"
    }
  },
  "required": ["pikachuName", "age"]
}
`}</code></pre>
        <h2 {...{
          "id": "deployment-strategy",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#deployment-strategy",
            "aria-label": "deployment strategy permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Deployment strategy`}</h2>
        <p>{`It defines the growth type of how a configuration update is rolled out.`}</p>
        <h3 {...{
          "id": "linear",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#linear",
            "aria-label": "linear permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Linear`}</h3>
        <p>{`For this type, AWS AppConfig processes the deployment by increments of the growth factor evenly distributed over the deployment. For example, a linear deployment that uses a step percentage of 20 initially makes the configuration available to 20 percent of the targets. After 1/5th of the deployment time has passed, the system updates the percentage to 40 percent. This continues until 100% of the targets are set to receive the deployed configuration.`}</p>
        <p>{`The term "target" refers to your resource, in the above example, a web application that fetches configuration data from AWS AppConfig. When deploying a configuration, the growth factor and growth type determine how the configuration is distributed to the targets. In the example, with a growth factor of 50% and linear growth type, the first web application that fetches the configuration will receive the new data, while the second web application may not. The distribution of targets is unpredictable and I think it depends on the `}<a parentName="p" {...{
            "href": "https://repost.aws/questions/QUh51u1aXUQG65IYbQRhkI0A/how-does-app-config-service-figure-out-the-targets-for-a-deployment"
          }}>{`IP address of the targets`}</a>{` `}<em parentName="p">{`(based on my observations)`}</em>{`.`}</p>
        <h3 {...{
          "id": "exponential",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#exponential",
            "aria-label": "exponential permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Exponential`}</h3>
        <p>{`For this type, AWS AppConfig processes the deployment exponentially using the following formula: `}<inlineCode parentName="p">{`G*(2^N)`}</inlineCode>{`. In this formula, `}<inlineCode parentName="p">{`G`}</inlineCode>{` is the step percentage specified by the user and `}<inlineCode parentName="p">{`N`}</inlineCode>{` is the number of steps until the configuration is deployed to all targets.`}</p>
        <p>{`Example if the growth factor is 2:`}</p>
        <pre><code parentName="pre" {...{}}>{`2*(2^0)
2*(2^1)
2*(2^2)
`}</code></pre>
        <h3 {...{
          "id": "bake-time",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#bake-time",
            "aria-label": "bake time permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Bake time`}</h3>
        <p>{`The amount of time AWS AppConfig monitors CloudWatch for alerts and rollback if there are any. It could be 10 minutes, 1440 minutes/hours, or completely skipped.`}</p>
        <h3 {...{
          "id": "resource",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#resource",
            "aria-label": "resource permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Resource`}</h3>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/appconfig/latest/userguide/appconfig-creating-deployment-strategy.html"
            }}>{`https://docs.aws.amazon.com/appconfig/latest/userguide/appconfig-creating-deployment-strategy.html`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/appconfig/2019-10-09/APIReference/API_DeploymentStrategy.html"
            }}>{`https://docs.aws.amazon.com/appconfig/2019-10-09/APIReference/API_DeploymentStrategy.html`}</a></li>
        </ul>
        <h2 {...{
          "id": "theory",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#theory",
            "aria-label": "theory permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Theory`}</h2>
        <p>{`AWS AppConfig is a service that makes it easy for engineers to safely push out new features in a measured and gradual way. With AppConfig, you can easily manage and update your configurations without having to push code to production. This is especially helpful when you want to test different configurations like in A/B testing scenarios. It's a great way to roll out new features and test them out before making them live for everyone.`}</p>
        <p>{`The following paragraphs from this `}<a parentName="p" {...{
            "href": "https://aws.amazon.com/blogs/mt/using-aws-appconfig-feature-flags/"
          }}>{`blog post`}</a>{` explain it perfectly:`}</p>
        <blockquote>
          <p parentName="blockquote">{`The “earlier” way to release a feature is to first build the code, then test it in QA, and get ready to deploy to Production. Engineers need to align the code merge and deployment with the date and time when marketing wants it released. Once that is all ready to go, on release day, you push out your new code to Production, and hope everything goes smoothly. As long as timing goes well, your customers can access the feature all at once. Engineers feverishly monitor performance metrics to make sure that the new feature did not introduce instability. This is sometimes known as “a push-and-pray deployment” since you don’t know how your code will behave on Production until everybody already has it.`}</p>
        </blockquote>
        <blockquote>
          <p parentName="blockquote">{`Modern software practices use feature flags when releasing new functionality. Using this technique, engineers separate code from configuration data. One can hide their feature behind a configuration toggle (a feature flag) and deploy the code to Production. However, since the code is hidden behind their flag, customers cannot access the feature. The engineer can flip the flag for a very limited group of users (for example, just themselves, or just internal users) and test out the feature on Production. The engineer can then gradually open access to more and more users. Assuming no alarms are going off during the gradual release of the feature, and other metrics are where you expect them, the deployment continues safely until 100% of users have the new feature.`}</p>
        </blockquote>
        <h3 {...{
          "id": "similar-service",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#similar-service",
            "aria-label": "similar service permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Similar service`}</h3>
        <p><a parentName="p" {...{
            "href": "https://vercel.com/blog/edge-config-ultra-low-latency-data-at-the-edge"
          }}>{`Vercel Edge Config`}</a>{` is very similar to it and if we wanted to store our app configurations somewhere, Vercel would be the best choice because it works perfectly with Next.js.`}</p>
        <h2 {...{
          "id": "resources",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#resources",
            "aria-label": "resources permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Resources`}</h2>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/appconfig/latest/userguide/what-is-appconfig.html"
            }}>{`https://docs.aws.amazon.com/appconfig/latest/userguide/what-is-appconfig.html`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.youtube.com/watch?v=sq2HcRMLaLU"
            }}>{`Lambda Function Feature Flags`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://aws.amazon.com/blogs/aws/safe-deployment-of-application-configuration-settings-with-aws-appconfig/"
            }}>{`https://aws.amazon.com/blogs/aws/safe-deployment-of-application-configuration-settings-with-aws-appconfig/`}</a></li>
        </ul>
      </MarkdownWrapper>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      